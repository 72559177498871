import * as validators from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';

export function translatedValidators() {
    const { t } = useI18n();
    const withI18nMessage = validators.createI18nMessage({ t });

    const phoneValidator = validators.helpers.regex(/^[\d\s\-+/()]+$/); // allow numbers, space, +-, (), / und -
    const ibanValidator = validators.helpers.regex(/^[A-Z]{2}[0-9]{2}\s?[A-Z0-9\s]{1,37}$/); //2 letters, 2 digits, optional space, then max 30 other digits and possible 7 spaces
    const bicValidator = validators.helpers.regex(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/); //6 lettets, 2 letter/digits, 3 optional letter/digits

    return {
        required: withI18nMessage(validators.required),
        requiredIf: withI18nMessage(validators.requiredIf, { withArguments: true }),
        sameAs: withI18nMessage(validators.sameAs, { withArguments: true }),
        email: withI18nMessage(validators.email),
        phone: withI18nMessage(phoneValidator),
        minLength: withI18nMessage(validators.minLength, {
            withArguments: true,
            messageParams: (params) => ({ ...params, limit: params.min }),
        }),
        maxLength: withI18nMessage(validators.maxLength, {
            withArguments: true,
            messageParams: (params) => ({ ...params, limit: params.max }),
        }),
        minValue: withI18nMessage(validators.minValue, { withArguments: true }),
        maxValue: withI18nMessage(validators.maxValue, { withArguments: true }),
        iban: withI18nMessage(ibanValidator),
        bic: withI18nMessage(bicValidator),
    };
}
